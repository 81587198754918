import React from 'react'
import { Link } from 'gatsby'

import SEO from 'components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { LinkArrowIcon, Stones, StoneStyles } from 'components/icons'
import { Nav } from 'components/nav'
import { TechSection, Swift, Java, Go, JavaScript, Kotlin, MongoDB, NodeJs, Python, Cpp } from 'components/icons/Technologies'
import { BackgroundWithGrid } from '../components/layout'
import { LdJsonLogo, HeaderLink } from '../components/semanticdata'
import Analytics from '../components/analytics'

const ExpertisePage = () => (
  <Layout className="bg-primary-2 text-primary-3">
    <Analytics />
    <SEO
      title="Expertise"
      pageUrl="/expertise/"
      description="Tumba Solutions is a trusted software development partner for a number of marquee companies. Company involvements vary from designing and developing mobile applications to building distributed systems at scale while transforming existing products with cutting edge technologies and concepts."
    />
    <LdJsonLogo />
    <MainWithMenu className="grid">
      <Header className="z-20 pt-6 mb-72 sm:h-48">
        <HeaderLink className="text-primary-2" />
      </Header>
      <BackgroundWithGrid image="expertise-hero" gridRow={1} />
      <Deco decoType={2} inner after gridRow={1} />
      <Section gridRow={2}>
        <h1 className="text-left text-primary-1 text-4xl lg:text-5xl">Expertise</h1>
      </Section>
      <Section className="z-20 pt-12 pb-48 grid gap-12 grid-cols-6 lg:grid-cols-12" gridRow={3}>
        <p className="col-span-6 text-lg sm:text-xl lg:text-2xl">
          Tumba Solutions is a trusted software development partner for a number of marquee companies. Company involvements vary from designing and developing mobile applications to building distributed systems at scale while transforming existing products
          with cutting edge technologies and concepts.
        </p>
        <div className="col-span-6 sm:col-span-5">
          <p>At Tumba we keep it simple - we adjust to your agile work process, provide predictable cost and ensure sustainable and unmatched product growth. Internally we follow the OKR methodology.</p>
          <p className="pt-6">We keep our teams small, senior software generalists, zero personnel turnover. Team Formats vary between 3-, 5- and 7 persons.</p>
        </div>
        <div className="col-span-6">
          <h2 id="technologies" className="col-span-5 text-2xl lg:text-3xl">
            Hero Skills
          </h2>
          <div className="mt-10 grid col-gap-4 row-gap-8 grid-cols-3">
            <TechSection Icon={Swift} rotate={0} text="Swift" />
            <TechSection Icon={Java} rotate={-75} text="Java" />
            <TechSection Icon={Kotlin} rotate={90} text="Kotlin" />
            <TechSection Icon={JavaScript} rotate={0} text="JavaScript" />
            <TechSection Icon={NodeJs} rotate={-75} text="Node.js" />
            <TechSection Icon={Cpp} rotate={90} text="C++" />
            <TechSection Icon={Python} rotate={0} text="Python" />
            <TechSection Icon={Go} rotate={-75} text="Go" />
            <TechSection Icon={MongoDB} rotate={90} text="MongoDB" />
          </div>
        </div>
        <div className="col-span-6 sm:col-span-5">
          <h2 id="product-projects" className="text-2xl lg:text-3xl">
            Products & Projects
          </h2>
          <p className="pt-10">We are always looking for sustainable business opportunities, such as partnerships and open collaboration initiatives. Investment opportunities are also on our radar.</p>
          <p className="pt-6">We are working on multiple in-house products in EMM and iHealth domains.</p>
          <p className="pt-6">Eager to learn more about the first Android native dedicated devices platform or a heart training technology built with user privacy in mind? Give us a shout!</p>
        </div>
      </Section>
      <Deco before after className="bg-primary-1" gridRow={4} />
      <Section className="z-20 pt-12 grid gap-12 grid-cols-3 text-primary-2" gridRow={4}>
        <h2 id="success-stories" className="col-span-3 text-2xl lg:text-3xl">
          Success stories
        </h2>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">
            It's been great working with Tumba. They bring good insight into product discussions. They can always be trusted to implement with minimal guidance. I appreciate their willingness to be flexible in their development process to accommodate changes
            in business.
          </p>
          <p className="mt-4 lg:mt-8">Ian, Director of Product</p>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">
            It's a delight working with Tumba, the quality of code is outstanding, even more the quality of the communication. Here's highest praise for an external company I can give: It's 100% like we're all part of the same team!
          </p>
          <p className="mt-4 lg:mt-8">Roben, Engineering Manager</p>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">
            Tumba has been an absolutely fantastic partner for us. Their engineers are truly part of our team, buy into our processes and values, and frequently offer suggestions for improvements. They have been incredibly flexible in accommodating our
            unpredictable needs.
          </p>
          <p className="mt-4 lg:mt-8">Michael, VP Engineering</p>
        </div>
      </Section>
      <Section className="z-20 pt-40 pb-24 lg:pb-40 grid gap-12 grid-cols-6 lg:grid-cols-11" gridRow={5}>
        <div className="col-span-6 lg:col-span-11">
          <Stones className="h-20 overflow-visible" stoneClasses="transform origin-center box-fill" styles={StoneStyles[2]} />
          <p className="pt-6 text-2xl lg:text-3xl">Explore</p>
        </div>
        <Link to="/services/" className="pt-8 col-span-4 flex items-baseline text-primary-1">
          <span className="text-lg">Services</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </Link>
        <Link to="/industries/" className="pt-8 col-span-4 flex items-baseline text-primary-1">
          <span className="text-lg">Industries</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </Link>
        <Link to="/team/" className="pt-8 col-span-3 flex items-baseline text-primary-1">
          <span className="text-lg">Team</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </Link>
      </Section>
      <Deco before decoType={1} gridRow={6} className="bg-primary-1" />
      <Nav className="z-20 text-primary-2" gridRow={7} />
    </MainWithMenu>
  </Layout>
)

export default ExpertisePage
